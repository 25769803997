import React, {Component} from 'react';
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import Axios from "axios";
import ApiUrl from "../AppUrl/ApiUrl";
import {toast} from "react-toastify";
import jwtDecode from "jwt-decode";
import moment from "moment";

class Presale extends Component {
    constructor() {
        super();
        this.state={
            token:"",wallet:[],bkcbal:"0",bal:"0",price:"0",target_symbol:"BNB",uid:"",
            loading:false,ico:[],target_logo:"K4MEZfSsQg-aow.png",amount:"10",id:"2",trx:[]
        }
    }
    componentDidMount() {
        this.getIco()
        const query = new URLSearchParams(this.props.location.search);
        var token1 = query.get('token')
        if(token1){
            this.setState({token:token1})
            console.log(token1)
            localStorage.setItem("authtoken",token1)
        }
        var token = localStorage.getItem("authtoken")
        if(token){
            var decoded = jwtDecode(token)
            this.setState({token:token,uid:decoded.uid})
            setTimeout(()=>{
                this.getWallet()
                this.getTrx()
            },200)
        }else {
            this.props.history.push("/login")
        }
        var ico = JSON.parse(localStorage.getItem("ico"))
        if(ico){
            this.setState({ico:ico,loading:false})
            setTimeout(()=>{
                this.getCurrentMarket()
            },50)
        }
        var walletdata = JSON.parse(localStorage.getItem("wallet"))
        if(walletdata){
            this.setState({wallet:walletdata,loading:false})
            setTimeout(()=>{
                this.getCurrentMarket()
            },50)
        }
    }
    getTrx=()=>{
        Axios.get(ApiUrl.baseurl+"ico-trx/"+this.state.uid)
            .then(res=>{
                if(res.data.length>0){
                    console.log(res.data)
                    this.setState({trx:res.data})
                }
            })
            .catch(err=>{
                //
            })
    }
    getIco=()=>{
        Axios.get(ApiUrl.baseurl+"all-ico")
            .then(res=>{
                //console.log(res.data)
                if(res.data.length>0){
                    this.setState({ico:res.data})
                    localStorage.setItem("ico",JSON.stringify(res.data))
                }
                setTimeout(()=>{
                    this.getCurrentMarket()
                },50)
            })
            .catch(err=>{
                //this.getWallet()
            })
    }
    getWallet=()=>{
        var formd = new FormData()
        formd.append("token",this.state.token)
        Axios.post(ApiUrl.baseurl+"auth/mywallet",formd)
            .then(res=>{
                console.log(res.data.data)
                if (res.data.success){
                    if(res.data.data.length>0){
                        this.setState({wallet:res.data.data})
                        localStorage.setItem("wallet",JSON.stringify(res.data.data))
                    }
                    setTimeout(()=>{
                        this.getCurrentMarket()
                    },500)
                }
            })
            .catch(err=>{
                //this.getWallet()
            })
    }

    getCurrentMarket=()=>{
        this.state.ico.map(res=>{
            if(this.state.id==res.id || this.state.target_symbol==res.target_symbol){
                this.setState({target_symbol:res.target_symbol,
                    target_logo:res.target_logo,id:res.id,price:res.price})
            }
        })
        this.state.wallet.map(res=>{
            if(res.coin_symbol=="KOCO" ){
                this.setState({bkcbal:res.balance})
            }
            if(res.coin_symbol==this.state.target_symbol ){
                this.setState({bal:res.balance})
            }
        })
    }

    margetCh=(e)=>{
        this.state.ico.map(res=>{
            if(e.target.value==res.id){
                this.setState({target_symbol:res.target_symbol,
                    target_logo:res.target_logo,id:res.id,price:res.price})
            }
            setTimeout(()=>{
                this.getCurrentMarket()
            },50)
        })
    }

    amount=(e)=>{
        this.setState({amount:e.target.value})
    }

    errorMsg=(val)=>{
        toast.error(val, {
            theme: "colored",
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    buyNow=()=>{
        this.setState({loading:true})
        var formd = new FormData()
        formd.append("token",this.state.token)
        formd.append("id",this.state.id)
        formd.append("amount",this.state.amount)
        Axios.post(ApiUrl.baseurl+"auth/buycoin",formd)
            .then(res=>{
                if(res.data.success){
                    this.successMsg(res.data.success)
                    this.getWallet()
                    this.getTrx()
                }else {
                    this.errorMsg(res.data.error)
                }
                this.setState({loading:false})
            })
            .catch(err=>{
                //console.log(err)
                this.setState({loading:false})
            })
    }

    render() {
        var val=this.state
        var ilist = this.state.ico.map(res=>{
            return(
                <option selected={res.target_symbol==val.target_symbol} value={res.id}>{res.target_symbol}</option>
            )
        })

        var trxl = this.state.trx.map(res=>{
            var tl = (parseFloat(res.amount)*parseFloat(res.price))
            return(
                <tr>
                    <td> {res.amount} { res.base_symbol}</td>
                    <td className="text-center"> {res.price} { res.target_symbol}</td>
                    <td> {tl.toFixed(tl<1?6:2)} { res.target_symbol}</td>
                    <td> {moment(res.created_at).format(("YYYY-MM-DD HH:mm:ss"))}</td>
                </tr>
            )
        })
        return (
            <>
                <TopMenu title="KOCO Coin Pre-Sale "/>
                <div className="presale">
                    <div className="row m-0">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <div className="presalemain">
                                <h3 className="mt-3 mb-3">buy KOCO </h3>
                                <br/>
                                <p>Amount
                                    <span style={{float:"right"}}>Balance : {parseFloat(val.bkcbal).toFixed(4)} KOCO</span>
                                </p>
                                <div className="inputd">
                                   <div className="left">
                                       <input value={val.amount} onChange={this.amount} type="number" placeholder="0"/>
                                   </div>
                                    <div className="right">
                                        <img src="/icon.png"/> KOCO
                                    </div>
                                </div>

                                <p>Price
                                    <span style={{float:"right"}}>Balance : {parseFloat(val.bal).toFixed(4)} {val.target_symbol}</span>
                                </p>
                                <div className="inputd">
                                    <div className="left">
                                        <input disabled={true} value={val.price} placeholder="0"/>
                                    </div>
                                    <div className="right">
                                        <img src={ApiUrl.photoUrl+val.target_logo}/>
                                        <select onChange={this.margetCh} style={{border:"none",outline:"none",width:"80px"}}>
                                            {ilist}
                                        </select>
                                    </div>
                                </div>
                                <p>You will receive
                                    <span style={{float:"right"}}></span>
                                </p>
                                <div className="inputd">
                                    <div className="left" style={{width:"100%",borderRight:"none"}}>
                                        <input value={val.amount +" "+"KOCO"} disabled={true} placeholder="0"/>
                                    </div>
                                </div>
                                <p>You will spend
                                    <span style={{float:"right"}}></span>
                                </p>
                                <div className="inputd">
                                    <div className="left" style={{width:"100%",borderRight:"none"}}>
                                        <input value={(parseFloat(val.amount)*parseFloat(val.price)).toFixed(6) +" "+ val.target_symbol} disabled={true}  placeholder="0"/>
                                    </div>
                                </div>
                                <p>I hereby agree to the KOCO purchase agreement and KOCO sale terms.</p>
                                <br/>
                                <p style={{color:"black"}}>Minimum Buy 10 KOCO</p>
                                <p style={{color:"black"}}>Maximum Buy 20000 KOCO</p>
                                <br/>
                                <button disabled={val.loading}
                                        onClick={this.buyNow}>
                                    {
                                     val.loading==true?"Loading...":"Buy KOCO"
                                     }
                                </button>
                                <br/><br/>

                            </div>
                        </div>
                    </div>

                    {
                        this.state.trx.length>0?
                            <div className="row m-0">
                                <div className="col-md-3"></div>
                                <div className="col-md-6">
                                    <div className="presalemain">
                                        <h5>My History</h5>
                                        <div className="table-responsive-sm">
                                            <table className="table">
                                                <tr>
                                                    <th>Buy Amount</th>
                                                    <th className="text-center">Price</th>
                                                    <th>Total Spend</th>
                                                    <th className="text-center">Time</th>
                                                </tr>
                                                {trxl}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>:""
                    }

                </div>

                <Footer/>
            </>
        );
    }
}

export default Presale;