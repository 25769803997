import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Footer from "../Footer";
import Axios from "axios";
import ApiUrl from "../../AppUrl/ApiUrl";
import Wallet from "../../page/Wallet";
import {toast} from "react-toastify";

class Spot extends Component {
    constructor() {
        super();
        this.state={
            bal:"show",smallbal:"show",btc:"0",token:"",wallet:[],name:"",
            loading:true
        }
    }
    componentDidMount() {
        var token = localStorage.getItem("authtoken")
        if(token){
            this.setState({token:token})
            setTimeout(()=>{
                this.getWallet()
            },500)
        }else {
            this.props.history.push("/login")
        }
        var walletdata = JSON.parse(localStorage.getItem("wallet"))
        if(walletdata){
            this.setState({wallet:walletdata,loading:false})
        }
        var btc = localStorage.getItem("btc")
        if(btc){
            this.setState({btc:btc})
        }
        Axios.get("https://api.coinpaprika.com/v1/tickers/btc-bitcoin")
            .then(res=>{
                //console.log(res.data.quotes["USD"]["price"])
                var bal =res.data.quotes["USD"]["price"]
                localStorage.setItem("btc",bal)
                this.setState({btc:bal})
            })
            .catch(err=>{
                //console.log(err)
            })
    }
    getWallet=()=>{
        var formd = new FormData()
        formd.append("token",this.state.token)
        Axios.post(ApiUrl.baseurl+"auth/mywallet",formd)
            .then(res=>{
                //console.log(res.data.data)
                if (res.data.success){
                    if(res.data.data.length>0){
                        this.setState({wallet:res.data.data,loading:false})
                        localStorage.setItem("wallet",JSON.stringify(res.data.data))
                    }
                }
            })
            .catch(err=>{
                 //this.getWallet()
                localStorage.removeItem("authtoken")
            })
    }
    bal=(val)=>{
        this.setState({bal:val})
    }
    smallbal=()=>{
        this.setState({smallbal:this.state.smallbal=="show"?"hide":"show"})
    }

    name=(e)=>{
        this.setState({name:e.target.value})
    }
    successMsg=(val)=>{
        toast.info(val,  {
            theme: "colored",
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    convert=(val)=>{
        this.successMsg(val)
    }
    gotoDeposit=(val)=>{
        this.props.history.push(val)
    }
    gotoWithdrew=(val)=>{
        this.props.history.push(val)
    }
    render() {
        var usd=0
        var deskwallet = this.state.wallet.filter((val)=>{
            if(this.state.name==""){
                return val;
            }else if(val.coin_name.toLowerCase().includes(this.state.name.toLowerCase()) || val.coin_symbol.toLowerCase().includes(this.state.name.toLowerCase())){
                return val;
            }
        }).map(res=>{
            var value =(parseFloat(res.balance)*parseFloat(res.price))
            usd+=value
            return(
                <>
                    {
                        this.state.smallbal=="hide"?
                            parseFloat(res.balance)>0?
                                <tr>
                                    <td> <img src={ApiUrl.photoUrl+res.logo_img}/>
                                        {res.coin_name} ({res.coin_symbol})
                                    </td>
                                    <td className="text-center">{this.state.bal=="hide"?"*****":parseFloat(res.balance).toFixed(5)}</td>
                                    <td className="text-center">{this.state.bal=="hide"?"*****":parseFloat(res.balance).toFixed(5)}</td>
                                    <td className="text-center">${this.state.bal=="hide"?"*****":(parseFloat(res.balance)*parseFloat(res.price)).toFixed(2)}</td>
                                    <td style={{textAlign:"right"}}>
                                        <Link to={"/wallet/deposit/?coin="+res.coin_symbol}>Deposit</Link>
                                        <Link to={"/wallet/withdrew/?coin="+res.coin_symbol}>Withdrew</Link>
                                    </td>
                                </tr>: "":
                            <tr>
                                <td> <img src={ApiUrl.photoUrl+res.logo_img}/>
                                    {res.coin_name} ({res.coin_symbol})
                                </td>
                                <td className="text-center">{this.state.bal=="hide"?"*****":parseFloat(res.balance).toFixed(5)}</td>
                                <td className="text-center">{this.state.bal=="hide"?"*****":parseFloat(res.balance).toFixed(5)}</td>
                                <td className="text-center">${this.state.bal=="hide"?"*****":(parseFloat(res.balance)*parseFloat(res.price)).toFixed(2)}</td>
                                <td style={{textAlign:"right"}}>
                                    <Link to={"/wallet/deposit/?coin="+res.coin_symbol}>Deposit</Link>
                                    <Link to={"/wallet/withdrew/?coin="+res.coin_symbol}>Withdrew</Link>
                                </td>
                            </tr>
                    }

                </>
            )
        })

        var mwallet = this.state.wallet.filter((val)=>{
            if(this.state.name==""){
                return val;
            }else if(val.coin_name.toLowerCase().includes(this.state.name.toLowerCase()) || val.coin_symbol.toLowerCase().includes(this.state.name.toLowerCase())){
                return val;
            }
        }).map(res=>{
            return(
                <>
                    {
                        this.state.smallbal=="hide"?
                            parseFloat(res.balance)>0?
                                <div className="walletmobile" data-toggle="modal" data-target={"#exampleModal"+res.coin_symbol}>
                                    <div className="modal fade" id={"exampleModal"+res.coin_symbol} tabIndex="-1" role="dialog"
                                         aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content"
                                                 style={{marginTop:"250px",
                                                     border:"1px solid #0085FF",
                                                     borderRadius:"20px"
                                                 }}>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div data-dismiss="modal" aria-label="Close" className="col-sm-6">
                                                            <button onClick={this.gotoDeposit.bind(this,"/wallet/deposit/?coin="+res.coin_symbol)}>Deposit {res.coin_symbol}</button>
                                                        </div>
                                                    </div>
                                                    <div data-dismiss="modal" aria-label="Close" className="col-sm-6">
                                                        <button onClick={this.gotoWithdrew.bind(this,"/wallet/withdrew/?coin="+res.coin_symbol)}>Withdrew {res.coin_symbol}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-2">
                                            <img src={ApiUrl.photoUrl+res.logo_img}/>
                                        </div>
                                        <div className="col-4">
                                            <p>
                                                {res.coin_symbol}<br/>
                                                {res.coin_name}
                                            </p>
                                        </div>
                                        <div className="col-6">
                                            <p className="float-end">
                                                <span className="float-end">{this.state.bal=="hide"?"*****":parseFloat(res.balance).toFixed(5)}</span>
                                                <br/>
                                                ≈ {this.state.bal=="hide"?"*****":(parseFloat(res.balance)*parseFloat(res.price)).toFixed(2)} USD
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                : "":
                            <div className="walletmobile" data-toggle="modal" data-target={"#exampleModal"+res.coin_symbol}>
                                <div className="modal fade" id={"exampleModal"+res.coin_symbol} tabIndex="-1" role="dialog"
                                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content"
                                             style={{marginTop:"250px",
                                                 border:"1px solid #0085FF",
                                                 borderRadius:"20px"
                                             }}>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div data-dismiss="modal" aria-label="Close"  className="col-sm-6">
                                                        <button onClick={this.gotoDeposit.bind(this,"/wallet/deposit/?coin="+res.coin_symbol)}>Deposit {res.coin_symbol}</button>
                                                    </div>
                                                </div>
                                                <div data-dismiss="modal" aria-label="Close" className="col-sm-6">
                                                    <button onClick={this.gotoWithdrew.bind(this,"/wallet/withdrew/?coin="+res.coin_symbol)}>Withdrew {res.coin_symbol}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-2">
                                        <img src={ApiUrl.photoUrl+res.logo_img}/>
                                    </div>
                                    <div className="col-4">
                                        <p>
                                            {res.coin_symbol}<br/>
                                            {res.coin_name}
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <p className="float-end">
                                            <span className="float-end">{this.state.bal=="hide"?"*****":parseFloat(res.balance).toFixed(5)}</span>
                                            <br/>
                                            ≈ {this.state.bal=="hide"?"*****":(parseFloat(res.balance)*parseFloat(res.price)).toFixed(2)} USD
                                        </p>
                                    </div>
                                </div>
                            </div>
                    }

                </>
            )
        })


        return (
            <Wallet>
                <div className="spot">
                    <div className="head">
                        <div className="row">
                            <div className="col-md-6">
                                <h3>Spot Balance <Link className="historybtn" to="/wallet/history"><i className="far fa-book float-end"></i></Link></h3>
                                <p>Estimated Balance
                                    {this.state.bal=="show"?
                                        <i onClick={this.bal.bind(this,"hide")} className="fas fa-eye"></i>:
                                        <i onClick={this.bal.bind(this,"show")} className="fas fa-eye-slash"></i>
                                    }
                                </p>
                                <h2>{this.state.bal=="hide"?"*****":(usd/parseFloat(this.state.btc)).toFixed(5)} BTC ≈{this.state.bal=="hide"?"*****":usd.toFixed(2)} USD</h2>
                                <p></p>
                            </div>
                            <div className="col-md-6 ">
                                <Link className="float-end" to="#">Transfer</Link>
                                <Link className="float-end" to="/wallet/withdrew">Withdrew</Link>
                                <Link className="float-end" to="/wallet/deposit">Deposit</Link>
                            </div>
                        </div>
                    </div>
                    <div className="walletlist">
                        <div className="row mb-3 mt-3 topinput">
                            <div className="col-md-4 col-5">
                                <h4>Assets List</h4>
                            </div>
                            <div className="col-md-3 col-7">
                                <div className="search">
                                    <i className="fas fa-search"></i>
                                    <input onChange={this.name} value={this.state.name} placeholder="Search" />
                                </div>
                            </div>
                            <div className="col-md-2 col-6">
                                <p className="mt-2">
                                    <input className="checkbox" onClick={this.smallbal} checked={this.state.smallbal=="hide"}
                                           type="checkbox"/> Hide Small Balances</p>
                            </div>
                            <div className="col-md-3 col-6" style={{paddingLeft:"0"}}>
                                <p className="convertb" style={{color:"#0085FF",cursor:"pointer"}}>
                                    <Link style={{textDecoration:"none"}} to="/convert">
                                    <i className="fas fa-retweet" style={{paddingRight:"5px"}}></i>
                                    Convert small balances to KOCO</Link> </p>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Coin</th>
                                <th className="text-center">Total Blance</th>
                                <th className="text-center">Available</th>
                                <th className="text-center">USD</th>
                                <th style={{textAlign:"right"}}>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {deskwallet}
                            </tbody>
                        </table>

                        {mwallet}
                    </div>


                </div>
            </Wallet>
        );
    }
};

export default Spot;