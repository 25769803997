import React, {Component} from 'react';
import Axios from "axios";
import ApiUrl from "../../AppUrl/ApiUrl";
import moment from "moment";
import jwtDecode from "jwt-decode";

class Convert extends Component {
    constructor() {
        super();
        this.state={
            token:"",loading:true,limit:"10",data:[],uid:"",email:""
        }
    }
    componentDidMount() {
        var token = localStorage.getItem("authtoken")
        if(token){
            this.setState({token:token})
            var decoded = jwtDecode(token)
            //console.log(decoded)
            this.setState({uid:decoded.uid,email:decoded.email})
            setTimeout(()=>{
                this.getPayments()
            },200)
        }else {
            this.props.history.push("/login")
        }
    }
    getPayments=()=>{
        Axios.get(ApiUrl.baseurl+"my/convert/"+this.state.uid+"/"+this.state.limit)
            .then(res=>{
                //console.log(res.data.data)
                if(res.data.data.length>0){
                    this.setState({data:res.data.data,loading:false})
                }else{
                    this.setState({loading:false})
                }
            })
            .catch(err=>{
                //
            })
    }
    render() {
        var tableview = this.state.data.map(res=>{
            return(
                <tr>
                    <td> {res.id}</td>
                    <td style={{textAlign:"center"}}>{res.fromname}</td>
                    <td  style={{textAlign:"center"}}>{res.toname}</td>
                    <td style={{textAlign:"center"}}>{res.amount} {res.fromname}</td>
                    <td style={{textAlign:"right"}}>{(parseFloat(res.amount)*parseFloat(res.rate)).toFixed(4)} {res.toname}</td>
                    <td style={{textAlign:"right"}}>
                        {moment(res.created_at).format(("YYYY-MM-DD HH:mm:ss"))}
                    </td>
                </tr>

            )
        })
        var mobilehistory=this.state.data.map(res=>{
            return(
                <>
                    <div className="mobilehistory">
                        <h6>Converted
                            <span className="float-end">
                            -{res.amount} {res.fromname}<br/>
                                <span>
                                    +{(parseFloat(res.amount)*parseFloat(res.rate)).toFixed(2)} {res.toname}
                                </span>
                            </span>
                        </h6>
                        <p>{moment(res.created_at).format(("YYYY-MM-DD HH:mm:ss"))}
                            <span className={res.status=="Success"?"float-end text-success":
                                res.status=="Rejected"?
                                    "float-end text-danger":"float-end"}>{res.status}</span></p>
                        {
                            res.trx==""||res.trx==null?"":
                                <p>Trx <span><a className="float-end" target="_blank" href={res.explorer+"/tx/"+res.trx}>
                                    {res.trx.substring(0,10)}...</a></span>
                                </p>
                        }
                    </div>
                </>
            )
        })
        return (
            <div className="hdata table-responsive">
                <table className="table">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th style={{textAlign:"center"}}>From</th>
                        <th style={{textAlign:"center"}}>To</th>
                        <th style={{textAlign:"center"}}>Spent Amount</th>
                        <th style={{textAlign:"right"}}>Received</th>
                        <th style={{textAlign:"right"}}>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableview}
                    </tbody>
                </table>

                {mobilehistory}

                {this.state.loading==true?
                    <div className="d-flex justify-content-center " style={{marginTop:"100px"}}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>:""
                }
                {this.state.loading==false && this.state.data.length==0?
                    <p style={{marginTop:"100px",textAlign:"center"}}>
                        No Data
                    </p>
                    :""
                }

            </div>
        );
    }
}

export default Convert;