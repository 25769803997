import React,{Component,Fragment} from "react";
import {Switch,Route,Redirect} from "react-router-dom";
import Home from "../page/Home";
import NotFound from "../page/NotFound";
import Register from "../page/Register";
import Login from "../page/Login";
import ForgotPassword from "../page/ForgotPassword";
import Blog from "../page/Blog";
import BlogSingle from "../page/BlogSingle";
import Referral from "../page/Referral";
import Mining from "../page/Mining";
import TermsConditions from "../page/TermsConditions";
import About from "../page/About";
import Deposit from "../page/Deposit";
import Withdrew from "../page/Withdrew";
import OverView from "../components/wallet/OverView";
import Spot from "../components/wallet/Spot";
import Futures from "../components/wallet/Futures";
import Hisory from "../components/history/Hisory";
import ChangePassword from "../page/ChangePassword";
import Funding from "../components/wallet/Funding";
import Airdrop from "../page/Airdrop";
import Kyc from "../page/Kyc";
import Presale from "../page/Presale";
import Convert from "../page/Convert";

class Router extends Component {

    render() {
        return (
            <Fragment>
              <Switch>
                  <Route exact path="/" component={Home}/>
                  <Route exact path="/register" component={Register}/>
                  <Route exact path="/login" component={Login}/>
                  <Route exact path="/forgot-password" component={ForgotPassword}/>
                  <Route exact path="/news-blog" component={Blog}/>
                  <Route exact path="/news-blog/:id" component={BlogSingle}/>
                  <Route exact path="/my/referral" component={Referral}/>
                  <Route exact path="/mining" component={Mining}/>
                  <Route exact path="/wallet/overview" component={OverView}/>
                  <Route exact path="/wallet/spot" component={Spot}/>
                  <Route exact path="/wallet/futures" component={Futures}/>
                  <Route exact path="/wallet/funding" component={Funding}/>
                  <Route exact path="/wallet/history" component={Hisory}/>
                  <Route exact path="/wallet/deposit" component={Deposit}/>
                  <Route exact path="/wallet/withdrew" component={Withdrew}/>
                  <Route exact path="/change-password" component={ChangePassword}/>
                  <Route exact path="/kyc" component={Kyc}/>
                  <Redirect from='/wallet' to="/wallet/overview" />
                  <Route exact path="/terms" component={TermsConditions}/>
                  <Route exact path="/about" component={About}/>
                  <Route exact path="/airdrop" component={Airdrop}/>
                  <Route exact path="/presale" component={Presale}/>
                  <Route exact path="/convert" component={Convert}/>

                  <Route component={NotFound}/>
              </Switch>
            </Fragment>
         );
    }
}

export default Router;
